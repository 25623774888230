.servicesSection {
  padding-top: 20px;
}

.titleBlockWrapper > figure {
  background-image: url("./../../img/aboutUsBg.webp");
}

@media (min-width: 768px) {
  .servicesSection {
    padding: 40px 40px 0 40px;
  }
}
