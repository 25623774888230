/* titleBlockWrapper */
.titleBlockWrapper > figure {
  background-image: url("./../../../../img/homeBg2.webp");
}

.titleBlockWrapper > section {
  margin: 1rem auto;
  position: relative;
  top: -75px;
  min-width: auto;
  max-width: 340px;
}
.titleBlockWrapper > section > div > h2 {
  font-size: 2rem;
}

/* infoContainer */
.infoContainer {
  margin: 30px auto;
  padding: 10px;
  max-width: 800px;
}

.infoContainer > h3 {
  margin: 0;
  font-size: 30px;
  letter-spacing: 3px;
  font-weight: 600;
  color: var(--blue-default);
}

.infoContainer > p {
  line-height: 27px;
}

.infoContainer > p > li {
  list-style: none;
}

@media (min-width: 768px) {
  .titleBlockWrapper > section {
    max-width: 400px;
    margin: 0.9rem 5% 0 50%;
  }
}

@media (min-width: 992px) {
  .titleBlockWrapper > section {
    max-width: 400px;
    margin: -0.5rem 5% 0 49%;
    padding: 0px;
  }
}

@media (min-width: 1200px) {
  .titleBlockWrapper > section {
    margin: -1.5rem 5% 0 43%;
    max-width: 500px;
    padding: 0px;
  }
  .titleBlockWrapper > section > div > h2 {
    font-size: 3rem;
  }
}
