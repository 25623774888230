.infoBlock {
  position: relative;
  display: flex;
  flex-direction: column;
}

.border {
  --margin: 15px;
  display: none;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-left: var(--margin);
  margin-top: var(--margin);
  pointer-events: none;
}

.infoBlockGrey .border {
  border: 4px solid var(--grey-default);
}

.infoBlockWhite .border {
  border: 4px solid var(--white);
}

.content {
  flex-grow: 1;
  font-size: 16px;
  line-height: 28px;
  padding: 30px 20px;
}

.infoBlockGrey .content {
  background-color: var(--grey-default);
}
.infoBlockWhite .content {
  background-color: var(--white);
}

.title {
  margin: 0;
  font-size: 26px;
  line-height: 32px;
  color: var(--primary);
}

@media (min-width: 768px) {
  .border {
    display: block;
  }
  .title {
    max-width: 200px;
  }
}
