.desktopMenuWrapper {
  padding: 20px 0;
  display: none;
}

.languagesWrapper {
  display: inline-block;
  margin-left: 30px;
  border-left: 1px solid var(--primary);
}

.languageBtn {
  background-color: transparent;
  border-color: transparent;
}

.languageBtn abbr.langAbbr {
  text-decoration: none;
}

.languageBtn abbr,
.languageBtn abbr b {
  cursor: pointer;
  color: var(--primary);
}

.languageBtn abbr b,
.languageBtn abbr:hover {
  color: var(--red-default);
}

@media (min-width: 768px) {
  .desktopMenuWrapper {
    display: block;
  }
  .desktopMenuWrapper > a {
    font-family: "Montserrat", sans-serif;
  }
}
