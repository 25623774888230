.pageWrapper {
  max-width: 1440px;
  margin: 0 auto 100px auto;
}

/* TODO: розібратись з стилями */
/* @media (max-width: 767px) {
  .pageWrapper {
    max-width: 600px;
  }
} */
