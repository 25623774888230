.notFound {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-default);
}

.notFound > div > div {
  text-align: center;
  color: var(--red-default);
}

.notFound > div > .code,
.notFound > div > .smile {
  font-weight: 600;
  font-size: 90px;
}

.notFound > div > .text {
  font-size: 60px;
}
