@import "normalize.css";

body {
  min-width: 350px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  --white: #fdfdfd;
  --blue-default: #1a2b69;
  --red-default: #d41317;
  --grey-default: #f2f2f2;
  --black-default: #020202;

  --primary: var(--blue-default);
  --secondary: var(--red-default);
}

* {
  color: var(--black-default);
}
