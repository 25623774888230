.titleBlockWrapper > figure {
  height: 20rem;
  margin: 0;
  background-image: url("./../../img/homeBg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.titleBlockWrapper > section {
  background-color: var(--grey-default);
}

.titleBlockWrapper > section > div {
  padding: 20px;
}

.titleBlockWrapper > section > div > h2 {
  margin: 0;
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 600;
  color: var(--primary);
}

.titleBlockWrapper > section > div > span {
  display: block;
  border-top: 3px solid var(--blue-default);
  margin: 10px 0;
  width: 8rem;
}

.titleBlockWrapper > section > div > p {
  margin: 0;
  line-height: 20px;
}

@media (min-width: 768px) {
  .titleBlockWrapper > section {
    border-left: 2px solid var(--primary);
    max-width: 450px;
    margin: -6rem 4% 0 auto;
  }
}

@media (min-width: 992px) {
  .titleBlockWrapper > section {
    max-width: 600px;
    margin: -9rem 4% 0 auto;
  }
  .titleBlockWrapper > figure {
    height: 25rem;
  }
  .titleBlockWrapper > section > div {
    padding: 2.5rem;
  }
  .titleBlockWrapper > section > div > h2 {
    font-size: 3rem;
  }
  .titleBlockWrapper > section > div > span {
    border-top: 4px solid var(--blue-default);
  }
  .titleBlockWrapper > section > div > p {
    line-height: 30px;
  }
}

@media (min-width: 1600px) {
  .titleBlockWrapper > figure {
    height: 30rem;
  }
}
