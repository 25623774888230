.footerWrapper {
  padding: 30px 30px;
  margin-bottom: -7rem;
  background-color: var(--blue-default);
  color: var(--white);
}

.footerWrapper > h2 {
  margin: 0 0 30px 0;
  font-size: 55px;
  color: var(--white);
}

.itemsContainer > .item {
  padding: 10px 0;
}

.itemsContainer > .item > .itemTitle {
  letter-spacing: 2px;
  text-decoration: underline;
  font-size: 20px;
  color: var(--white);
}

.itemsContainer > .item > .itemValue {
  font-size: 20px;
  color: var(--white);
}

/* TODO: дописати стилі */
@media (min-width: 768px) {
  .footerWrapper {
    padding: 30px 60px;
  }
  .itemsContainer > .item {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .itemsContainer > .item > .itemValue {
    padding: 0 0 20px 40px;
    border-left: 1px solid var(--white);
  }
}

@media (min-width: 992px) {
  .footerWrapper {
    padding: 30px 60px;
  }
  .itemsContainer > .item {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .itemsContainer > .item > .itemValue {
    padding: 0 0 10px 40px;
    border-left: 1px solid var(--white);
  }
}
