.servicesSection {
  margin: 50px 0;
  position: relative;
}

.description,
.title {
  color: var(--white);
}

.title {
  margin: 0;
  padding: 20px 20px 0 20px;
  font-size: 55px;
}

.bg {
  background-color: var(--primary);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 385px;
  z-index: -1;
}

@media (min-width: 768px) {
  .servicesSection {
    margin: 100px 0;
  }
  .title {
    margin: 0;
    padding: 0 20px;
    font-size: 55px;
  }
}
