/* .infoBlocksList {
} */
.row {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.item {
  width: 100%;
  margin: 15px;
  margin-left: 0;
}

@media (min-width: 768px) {
  .row {
    flex-direction: row;
  }
  .item {
    max-width: 279px;
    min-height: 329px;
  }

  .item + .item {
    margin-left: 15px;
  }
}
