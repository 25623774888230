.button {
  letter-spacing: 1px;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary);

  text-decoration: none;
  font-family: "Lekton", sans-serif;
  transition: all 0.3s;
  text-decoration: underline;
}

.button:hover {
  color: var(--secondary);
}

@media (min-width: 768px) {
  .button {
    font-size: 0.8rem;
    margin: 0 3px;
  }
}

@media (min-width: 922px) {
  .button {
    font-size: 1rem;
    margin: 0 0.7rem;
  }
}

@media (min-width: 1200px) {
  .button {
    font-size: 1.4rem;
    margin: 0 0.7rem;
  }
}
