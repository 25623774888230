.backdrop {
  background-color: var(--white);
  min-height: 120vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2;
}

.mobileMenuWrapper .ulWrapper {
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  right: 0;
  background-color: var(--white);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.4s;
}

.menuOpen {
  transform: translateX(0%);
}
.menuClose {
  display: none;
  transform: translateX(100%);
}

.ulWrapper ul {
  list-style: none;
  padding-top: 65px;
}

.ulWrapper .languagesWrapper {
  height: 50px;
  line-height: 50px;
}

.mobileMenuWrapper .ulWrapper li {
  padding: 18px;
}

.mobileMenuWrapper .ulWrapper li > a {
  font-family: "Montserrat", sans-serif;
}

/* burger button */
.burgerContainer {
  position: relative;
  z-index: 3;
  display: inline-block;
  cursor: pointer;
}

.burgerContainer > * {
  width: 40px;
  height: 5px;
  background-color: var(--black-default);
  margin: 6px 0;
  border-radius: 15px;
  transition: 0.4s;
}

.openLine1 {
  transform: rotate(-45deg) translate(-9px, 6px);
}

.openLine2 {
  opacity: 0;
}

.openLine3 {
  transform: rotate(45deg) translate(-8px, -8px);
}

@media (min-width: 768px) {
  .mobileMenuWrapper {
    display: none;
  }
}

.line {
  height: 1px;
  background-color: var(--primary);
}

.languageBtn {
  background-color: transparent;
  border-color: transparent;
}

.languageBtn abbr.langAbbr,
.languageBtn abbr b {
  color: var(--primary);
}

.languageBtn abbr.langAbbr {
  text-decoration: none;
}
