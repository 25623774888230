.view {
  background-color: var(--grey-default);
}

.bg {
  background-color: var(--primary);
  width: 100%;
  height: 200px;
}

.content {
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoBlock {
  width: 80%;
}

.infoBlockTitle {
  max-width: 100%;
  line-height: 40px;
  font-size: 40px;
  overflow-wrap: break-word;
}

.infoBlockContent {
  padding: 47px;
}

.descriptionSection {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 30px;
}

.line {
  width: 80px;
  height: 4px;
  background-color: var(--blue-default);
}
.description {
  font-size: 16px;
  letter-spacing: 0.48px;
  margin-top: 30px;
  line-height: 27px;
}

.link {
  display: block;
  text-decoration: none;
  color: var(--secondary);
  margin-top: 30px;
}

@media (min-width: 768px) {
  .infoBlock {
    width: 100%;
    max-width: 750px;
  }

  .descriptionSection {
    width: 100%;
    flex-direction: row;
    max-width: 750px;
    margin-top: 100px;
  }

  .line {
    width: 80px;
    height: 4px;
    margin-top: 8px;
  }

  .description {
    margin-left: 40px;
    margin-top: 0;
    max-width: 500px;
  }
}

@media (min-width: 1200px) {
  .infoBlockTitle {
    line-height: 66px;
    font-size: 66px;
  }
}
