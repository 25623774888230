.description, span {
  display: block;
}

.description,
.link {
  margin-top: 25px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.link {
  display: inline-block;
  text-decoration: none;
  color: var(--secondary);
  transition: all 0.3s;
}

.link:hover {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--blue-default);
}
