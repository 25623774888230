.headerWrapper {
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 20px;
  justify-content: space-between;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  width: 35px;
  filter: saturate(12) drop-shadow(8px 5px 1px rgba(0, 0, 0, 0.336));
}

.textContainer {
  padding: 0 0 0 10px;
  line-height: 0.8rem;
}

.textContainer > div {
  color: var(--blue-default);
  font-weight: bold;
  font-family: "Orbitron", sans-serif;
}

.textContainer > div:nth-child(1) {
  letter-spacing: 5px;
}

.textContainer > div:nth-child(2n) {
  font-size: 0.8rem;
}

.isActive {
  color: var(--secondary);
}

@media (min-width: 768px) {
  .logo {
    width: 40px;
  }
  .headerWrapper {
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .logo {
    width: 45px;
  }
  .headerWrapper {
    padding: 10px 15px 10px 20px;
  }
}

@media (min-width: 1200px) {
  .textContainer {
    line-height: 0.9rem;
  }
  .textContainer > div:nth-child(1n) {
    font-size: 1.2rem;
  }
  .textContainer > div:nth-child(2n) {
    font-size: 0.9rem;
  }

  .headerWrapper {
    padding: 30px 25px 30px 30px;
  }
}
