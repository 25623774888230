.partnersWrapper {
  margin: 50px 0;
}

.partnersWrapper > h2 {
  color: var(--white);
  background-color: var(--blue-default);
  font-size: 55px;
  padding: 40px 20px 90px 20px;
}

.logosContainer {
  position: relative;
  z-index: 2;

  background-color: var(--grey-default);
  margin: -80px 0 100px 0;
  padding: 50px 5px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1vw;

  align-items: center;
  justify-items: center;
  justify-content: center;
}

.logosContainer > .item {
  padding: 5px;
  max-width: 7rem;
  text-align: center;
}

.logosContainer > .item > figure {
  margin: 0 auto;
  width: 80%;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
}

.logosContainer > .item > figure:hover {
  filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.425));
}

@media (min-width: 768px) {
  .partnersWrapper > h2 {
    padding: 40px 60px 150px 60px;
  }
  .logosContainer {
    margin: -140px 40px 100px 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .logosContainer {
    margin: -140px 60px 100px 60px;
  }
}
